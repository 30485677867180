<template>
    <div class="giga-member_item" :class="[(type == 2 ? 'giga-two_profile' : ''), (type == 3 ? 'giga-three_profile' : '')]">
        <div class="giga-member_thumb">
            <span></span>
        </div>
        <div class="giga-member_meta">
            <div class="giga-member_info">
                <h4 class="giga-member_name">{{ fullName }}</h4>
              <ul class="giga-member_meta_list">
                <li class="giga-member_meta_item">{{ $t(profession) }}</li>
                <li v-if="type == 2" class="giga-member_meta_item">{{phone}}</li>
              </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'teamItem',
    props: ['firstname', 'lastname', 'profession', 'phone', 'type'],
    computed: {
      fullName() {
        const firstname = this.firstname ? this.firstname : '';
        const lastname= this.lastname ? this.lastname : '';
        return `${firstname}  ${lastname}`;
      }
    }
}
</script>